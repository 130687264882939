import { graphql, useStaticQuery } from "gatsby"

import Layout from '../layouts'
import PageHeader from '../components/page/header'
import Photos from '../components/photos'
import React from 'react'
import Seo from '../components/seo'
import Text from '../components/text'

const Therapy = () => {
  const { pageHeaderImage, photoOne, photoTwo, photoThree, photoFour } = useStaticQuery(
    graphql`
      {
        pageHeaderImage: imageSharp(resize: {originalName: {eq: "contact.jpg"}}) {
          gatsbyImageData(
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1000
          )
        }

        photoOne: imageSharp(resize: {originalName: {eq: "ueber-mich-1.png"}}) {
          gatsbyImageData(
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1110
          )
        }

        photoTwo: imageSharp(resize: {originalName: {eq: "ueber-mich-2.jpg"}}) {
          gatsbyImageData(
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1110
          )
        }

        photoThree: imageSharp(resize: {originalName: {eq: "ueber-mich-3.jpg"}}) {
          gatsbyImageData(
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1110
          )
        }

        photoFour: imageSharp(resize: {originalName: {eq: "ueber-mich-4.jpg"}}) {
          gatsbyImageData(
            outputPixelDensities: [0.25, 0.5, 1, 2, 3]
            quality: 75
            transformOptions: {
              fit: COVER
            }
            width: 1110
          )
        }
      }
    `
  )

  return (
    <Layout>
      <Seo
        title='Über mich'
        currentUrl='/ueber-mich'
      />
      <PageHeader
        headline='Über mich'
        copy='Ich habe mittlerweile seit über 10 Jahren professionelle Erfahrung in der Verbindung von funktionellem Training mit angewandter Neurologie und Krafttraining.'
        image={pageHeaderImage}
      />
      <Text text={`
## Kindheit und Jugend
Aufgewachsen mit Geräteturnen begeisterte ich mich im Jugendalter schnell für viele weitere Sportarten wie Kanu-Sport, Kraftsport, Fußball und Schwimmen. Geprägt von zahlreichen Verletzungen beschäftigte ich mich bereits vor dem Studium selbstständig mit Sport- und Physiotherapie sowie optimales Athletik-Training.
      `}/>
      <Text text={`
## Ab 2010
Start des Studiums Prävention-Rehabilitation-Fitnesssport. Neben dem Studieren probierte ich neue Sportarten wie Capoeira, Aikido, BJJ, MMA sowie Breakdance aus. Letztere hat sich tief in meinem Herzen eingebrannt und begleitet mich bis heute. Im zweiten Studienjahr erlitt ich einen vorderen Kreuzbandriss im Knie, welcher das Interesse an eigenständigem Fortbilden nochmals verstärkte. Getrieben vom Wissensdurst arbeitete ich neben dem Studium in einem Gesundheitsstudio, welches mich weiter in vielfältigen Bereichen ausbildete.
      `}/>
      <Photos photos={[{ photo: photoOne }]}/>
      <Text text={`
## 2016
Durch ein freiwilliges Praktikum beim damaligen 3. Bundesliga Verein CFC lernte ich den Physiotherapeuten Sebastian Lange kennen, was alles verändern sollte. Ich bekam in der Sportphysiotherapie sowie durch das damalige Fit Team Chemnitz die Möglichkeit mit Spitzsportlern zusammen zu arbeiten sowie die unterschiedlichsten Menschen mit Personaltraining zu unterstützen.
Ein paar Monate später lernte ich den Bundestrainer im Sprint, Jörg Möckel kennen. Er begeisterte mich mit dem Konzept der Neuroathletik und seiner eigenen Trainingsphilosophie. Ich wollte unbedingt mehr über die Prinzipien der Neurologie wissen. Wir kooperierten zusammen, indem ich seinen Athleten Funktionelles Yoga gab und ich einen Wissensaustausch genießen konnte.
      `}/>
      <Text text={`
## 2017
Ich nutze seitdem die Möglichkeit nicht nur mit Spitzensportlern zu arbeiten, sondern auch die Rehabilitation von Patienten mit Schlaganfällen, Kreuzbandrissen oder Hüft-TEPs zu verbessern. Ich versteht jetzt, welche Aspekte des zentralen Nervensystems essentiell für die Verbesserung von Bewegungsmustern sind und was gesunde Bewegung ausmacht. Zur gleichen Zeit bekam ich die Chance in der Niners Academy das Athletik-Training zu führen. Im August des Jahres wurde ich schließlich der Athletik-Trainer für die Profi- und Nachwuchsabteilung der Niners Chemnitz.
      `}/>
      <Photos photos={[{ photo: photoTwo }]}/>
      <Text text={`
## 2018
Gleichzeitig zum Basketball und dem Personaltraining war ich ab Januar der Co-Trainer von Jörg Möckel für Profi- und Nachwuchssportler im Sprint- und Hürdenlauf am Olympiastützpunkt Chemnitz. Ich nahm in der Zeit unfassbar viel Wissen über Trainingsplanung -gestaltung, Krafttraining, Laufen, Regeneration und Neuro-Athletik mit.
Parallel absolvierte ich Fortbildungen zu Schmerztherapie, Kinesiologie, Stress- und Ernährungscoaching.
      `}/>
      <Text text={`
## 2019 - Auf zu neuen Ufern.
Nach 9 Jahren leben, wachsen und arbeiten verlasse ich Chemnitz und ziehe mit Frau und Kind nach Wetzlar. Das Sprint Team Wetzlar nahm meine Familie und mich warmherzig auf und vermittelte mich sofort an die besten Kontakte der Region weiter. Dadurch lernte ich viele neue Menschen kennen, welche ich auch heute noch in Sachen Gesundheit und Training begleite.
      `}/>
    <Photos photos={[{ photo: photoThree }]}/>
      <Text text={`
## 2021
Ich erkenne, dass es ein großes Bedürfnis nach einem Trainingsstil gibt, der funktionelles Training, Krafttraining, athletisches Training und angewandte Neurologie auf eine intelligente Art, für jede Art von Mensch kombiniert. In Workshops unterrichtet ich außerdem andere Trainer und Therapeuten, wie sie die Prinzipien der angewandten Neurologie in ihren Trainings und Therapieeinheiten umsetzen können.
      `}/>
    <Photos photos={[{ photo: photoFour }]}/>
      <Text text={`
Dieses Konzept, das Training integrativ zu gestalten und durch Reaktion, Beweglichkeit, Kraft und Koordinationsübungen mehr physische Systeme einzubeziehen, ist das Markenzeichen von Unleashed Therapy geworden. Das wichtigste dabei ist, dass Intelligentes Training immer mit Spaß einhergehen muss –und das macht Unleashed Therapy so besonders.

__INTELLIGENTES, DYNAMISCHES TRAINING MIT HERZBLUT UND LEIDENSCHAFT__
      `}/>
    </Layout>
  )
}

export default Therapy
